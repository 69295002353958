const text = (
  <>
    {" "}
    Durch die Entwicklung in Hamburg mit stetig steigenden Mieten und fehlendem Wohnraum sehen wir uns mit der Sorge
    konfrontiert, keine langfristige Perspektive in der Stadt zu haben. Auch im Hinblick auf unsere Kinder möchten wir
    für Stabilität und Wohnraumsicherheit sorgen. Der genossenschaftliche Gedanke bietet uns die Möglichkeit, sich
    zusammenzuschließen, Synergien zu nutzen und dem wohnungswirtschaftlichen Druck entgegenzustehen. Wir wollen unseren
    Kindern ein Miteinander vorleben, indem man sich gegenseitig unterstützt, aufeinander achtet und sich mit seinen
    Mitmenschen auseinandersetzt. Unsere Kinder sollen lernen, was es heißt, eine Gemeinschaft zu sein.
    <br />
    <br />
    Wir wünschen uns eine starke und solidarische Gemeinschaft, die mehr sein soll als bloßes nebeneinander Wohnen. Dies
    wollen wir durch Schaffung von gemeinschaftlichen Räumen und Aktivitäten umsetzen. Diese Idee schließt alle
    BewohnerInnen mit ein und wirkt auch über das einzelne Baufeld hinweg auf das umliegende Quartier.
    <br />
    <br />
    Wir schätzen die Diversität an BewohnerInnen im Umfeld und wollen uns als Gemeinschaft für die Integration von
    Geflüchteten engagieren. Zwei Wohnungen sollen mit alleinerziehenden Geflüchteten belegt werden, die wir langfristig
    in unsere Gemeinschaft integrieren wollen. Auch der geplante Gemeinschaftsraum soll für Aktivitäten zu dem Thema
    Integration zur Verfügung gestellt werden und die Gemeinschaft als Ganzes stärken.
    <br />
    <br />
    Wir wollen unser Gebäude so gestalten, dass es sich an verändernde Lebenssituationen anpassen lässt. Wenn die Kinder
    aus dem Haus sind, sollen sich durch das Zusammenlegen von Wohnungen und durch einfache bauliche Maßnahmen neue
    Möglichkeiten des Zusammenlebens im Alter ergeben. Freiwerdender Wohnraum kann neu an jüngere Familien vergeben
    werden, so dass langfristig ein Mehrgenerationenhaus entstehen kann.
  </>
);

export const Motivation = {
  className: "motivation",
  titlePrimary: "Unsere Motivation",
  titleSecondary: "Ein Zuhause für alle: Gemeinschaftlich Wohnen in Hamburg!",
  content: text,
};
