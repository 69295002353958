import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { List, ListItem } from "@mui/material";

const linkItems = [
  {
    url: "https://www.ifbhh.de/programme/immobilienwirtschaft/mietwohnungen-bauen/mietwohnungen-bauen/baugemeinschaften-mit-genossenschaftlichem-eigentum",
    title: "IFB Hamburg - Baugemeinschaften mit genossenschaftlichem Eigentum",
  },
  {
    url: "https://www.ifbhh.de/api/services/document/1171",
    title: "IFB Hamburg - Förderrichtlinie Baugemeinschaften (PDF)",
  },
  {
    url: "https://www.ifbhh.de/services/finanzass/einkommensrechner",
    title: "IFB Hamburg - Einkommensrechner",
  },
  {
    url: "https://www.iba-hamburg.de/de/projekte/wilhelmsburger-rathausviertel/uebersicht",
    title: "IBA Hamburg - Wilhelmsburger Rathausviertel",
  },
  {
    url: "https://www.iba-hamburg.de/files/downloads/Projekte/Projekte-Wilhelmsburg-%C3%BCbergeordnet/Praesentationen/230918_Projektdialog-2023_verkleinert.pdf",
    title: "IBA Hamburg - Präsentation Projektdialog 2023 (PDF)",
  },
  {
    url: "https://www.kfw.de/inlandsfoerderung/Privatpersonen/Neubau/F%C3%B6rderprodukte/Wohneigentumsprogramm-Genossenschaftsanteile-(134)/",
    title: "KfW - Förderung genossenschaftlichen Wohnens",
  },
];

const text = (
  <div className="flex flex-col gap-4 px-4">
    <List>
      {linkItems.map((item) => (
        <ListItem key={item.title} sx={{ display: "list-item" }}>
          <a
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
            className="underline decoration-transparent transition duration-300 ease-in-out hover:decoration-inherit hover:text-blue-500"
          >
            {item.title}
          </a>{" "}
          <OpenInNewIcon />
        </ListItem>
      ))}
    </List>
  </div>
);

export const Links = {
  className: "links",
  titlePrimary: "Zum Weiterlesen",
  titleSecondary: "Links",
  content: text,
};
