import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Box, Container, Grid, TextField } from "@mui/material";
import { useState } from "react";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [formStatus, setFormStatus] = useState({
    loading: false,
    submitted: false,
    error: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormStatus({ ...formStatus, loading: true });

    fetch(`${process.env.REACT_APP_URL_API}/contact/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(() => {
        setFormStatus({ submitted: true, error: false, loading: false });
        setFormData({ name: "", email: "", subject: "", message: "" });
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
        setFormStatus({ submitted: false, error: true, loading: false });
      });
  };

  return (
    <Container maxWidth="md" className="dark:bg-white rounded-md p-1">
      <Box
        sx={{
          mt: 5,
          mb: 5,
        }}
        className="dark:bg-white rounded p-2"
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Name" name="name" value={formData.name} onChange={handleChange} required />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="E-Mail"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="Betreff" name="subject" value={formData.subject} onChange={handleChange} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nachricht"
                name="message"
                multiline
                minRows={4}
                maxRows={12}
                value={formData.message}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  mt: 2,
                  py: 1,
                  fontSize: "1.1rem",
                }}
                endIcon={<SendIcon />}
                loading={formStatus.loading}
                loadingPosition="end"
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
          {formStatus.submitted && !formStatus.error && (
            <Container maxWidth="md" sx={{ mt: 2 }}>
              <Alert severity="success">Danke für deine Nachricht!</Alert>
            </Container>
          )}
          {formStatus.error && (
            <Container maxWidth="md" sx={{ mt: 2 }}>
              <Alert severity="error">
                Ein Fehler ist aufgetreten. Bitte versuche es erneut oder kontaktiere uns unter info@stadtlueued.de.
              </Alert>
            </Container>
          )}
        </form>
      </Box>
    </Container>
  );
};

export const Contact = {
  className: "contact",
  titlePrimary: "Kontakt",
  titleSecondary: "Schreib uns",
  content: <ContactForm />,
};
