import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Header from "../components/Header";
import TextBlockBlue from "../components/TextBlockBlue";
import TextBlockWhite from "../components/TextBlockWhite";
import { About } from "../components/pages/About";
import { CommonRooms } from "../components/pages/CommonRooms";
import { Contact } from "../components/pages/Contact";
import { Downloads } from "../components/pages/Downloads";
import { Links } from "../components/pages/Links";
import { Motivation } from "../components/pages/Motivation";
import { WhoWeSearch } from "../components/pages/WhoWeSearch";

export const pageArray = [About, Motivation, CommonRooms, WhoWeSearch, Downloads, Links, Contact];

const MainScreen = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Stadtlüüd - Baugemeinschaft</title>
        <meta
          name="description"
          content="Baugemeinschaft Stadtlüüd - Gemeinsam wohnen und leben südlich der Elbe. Erfahre mehr über unser Projekt, unsere Gemeinschaft und unsere Werte."
        />
        <meta
          name="keywords"
          content="Baugemeinschaft, Baugenossenschaft, Stadtlüüd, Hamburg, Gemeinschaft, Wohnen, Wilhelmsburg, Neubau, Mietwohnungen, Genossenschaft"
        />
        {/* Open Graph meta tags */}
        <meta property="og:title" content="Stadtlüüd - Baugemeinschaft" />
        <meta
          property="og:description"
          content="Baugemeinschaft Stadtlüüd - Gemeinsam wohnen und leben südlich der Elbe."
        />
        <meta property="og:url" content="www.stadtlueued.de" />
      </Helmet>
      <Header />
      {pageArray.map((page, index) => (
        <section key={page.titleSecondary} className={page.className}>
          {index % 2 === 0 ? (
            <TextBlockWhite titlePrimary={page.titlePrimary} titleSecondary={page.titleSecondary} text={page.content} />
          ) : (
            <TextBlockBlue titlePrimary={page.titlePrimary} titleSecondary={page.titleSecondary} text={page.content} />
          )}
        </section>
      ))}
      <Footer />
    </div>
  );
};

export default MainScreen;
