import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import EmailIcon from "@mui/icons-material/Email";
import LabelIcon from "@mui/icons-material/Label";
import MenuIcon from "@mui/icons-material/Menu";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Divider, Drawer, IconButton, ListItemIcon, ListItemText, MenuItem, MenuList } from "@mui/material";
import { useState } from "react";
import { Link } from "react-scroll";

const menuItems = {
  about: { text: "Wer wir sind", icon: <LabelIcon fontSize="small" /> },
  motivation: { text: "Unsere Motivation", icon: <LabelIcon fontSize="small" /> },
  commonRooms: { text: "Gemeinschaftsräume", icon: <LabelIcon fontSize="small" /> },
  whoWeSearch: { text: "Wen wir suchen", icon: <LabelIcon fontSize="small" /> },
  divider: { text: "", icon: "" },
  downloads: { text: "Downloads", icon: <DownloadIcon fontSize="small" /> },
  links: { text: "Links", icon: <OpenInNewIcon fontSize="small" /> },
  contact: { text: "Kontakt", icon: <EmailIcon fontSize="small" /> },
};

const Hamburger = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={() => setDrawerOpen(true)}
        className="bg-white dark:bg-gray-900 mr-8"
      >
        <MenuIcon />
      </IconButton>

      <Drawer variant="persistent" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <div className="flex justify-end bg-white dark:bg-gray-900 pr-4">
          <IconButton
            className="text-black dark:text-white"
            edge="end"
            color="inherit"
            aria-label="close"
            onClick={() => setDrawerOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="h-full bg-white dark:bg-gray-900 pl-2">
          <div className="text-center text-2xl font-abc-gravity dark:font-minion-pro text-black dark:text-white">
            Stadtlüüd
          </div>
          <MenuList>
            {Object.entries(menuItems).map(([link, data]) => {
              if (link === "divider") {
                return (
                  <div className="my-4" key={link}>
                    <Divider className="text-black dark:text-white bg-gray-900 dark:bg-white" />
                  </div>
                );
              } else {
                return (
                  <Link key={link} activeClass="active" smooth spy to={link}>
                    <MenuItem className="bg-white dark:bg-gray-900 hover:bg-gray-100 dark:hover:bg-gray-700">
                      <ListItemIcon className="text-black dark:text-white">{data.icon}</ListItemIcon>
                      <Box>
                        <ListItemText primary={data.text} className="text-center text-black dark:text-white" />
                      </Box>
                    </MenuItem>
                  </Link>
                );
              }
            })}
          </MenuList>
        </div>
      </Drawer>
    </>
  );
};

export default Hamburger;
