const text = (
  <>
    Wir Stadtlüüd sind Personen, die aus privatem und beruflichem Umfeld zusammenfinden, um ein gemeinsames
    Quartiersprojekt zu realisieren. Wir vereinen unterschiedlichste Berufsgruppen und Generationen. Durch unsere bunte
    Vielfalt entstehen viele Synergien und Unterstützungsmöglichkeiten. So können wir durch verschiedene Perspektiven
    die besten Ansätze sammeln, Erfahrungen und Kompetenzen bündeln und so zu einer lebendigen Entwicklung Hamburgs
    südlich der Elbe beitragen.
    <br />
    <br />
    Alle Mitglieder bringen sich mit Offenheit und Kompromissbereitschaft in die unterschiedlichen Themen ein, um den
    Traum vom gemeinsamen Wohnen und langfristigen „zu Hause“ zu realisieren. Bei wichtigen Entscheidungen werden die
    Tendenzen in der Gruppe respektiert und es wird ein Konsens gefunden, der von allen Mitgliedern getragen wird. Alle
    wollen das große gemeinsame Ziel erreichen. Daher sind uns der solidarische Gedanke, eine konstruktive
    Gesprächsführung und ein rücksichtsvoller Umgang untereinander sehr wichtig.
    <br />
    <br />
    Als Baugemeinschaft Stadtlüüd vereinen wir Familien mit kleinen Kindern in verschiedenen Altersgruppen, sowie Paare
    mit und ohne Kinderwunsch, als auch Einzelpersonen verschiedenen Alters. Unser alltägliches Leben findet
    Gleichgewicht zwischen Familie, Beruf, Beziehungen und Engagement. Gerade mit Kindern, aber auch im Alter möchten
    wir uns auf die gemeinsame Unterstützung, das Verständnis und die Freundschaft innerhalb der Gemeinschaft verlassen
    können. Mit dem Zusammenschluss zu einer Kleingenossenschaft suchen wir Beständigkeit und ein soziales Engagement
    füreinander.
    <br />
    <br />
    Unsere Gemeinschaft kommt aus verschiedenen Stadtteilen zusammen und pflegt somit eine enge Verbundenheit zu
    Hamburg. Auch der im Süden der Elbe gelegene Teil Hamburgs wird häufig für das nahe Naturerlebnis besucht. Dabei
    wird das besondere Potential dieser Stadtteile deutlich und es entsteht die Vision eines Lebens in enger
    Verbundenheit mit der grünen Umgebung und der neuen Nachbarschaft.
  </>
);

export const About = {
  className: "about",
  titlePrimary: "Wer wir sind",
  titleSecondary: "Stadtlüüd: Gemeinsam Wohnen und Leben südlich der Elbe",
  content: text,
};
