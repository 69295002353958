const text = (
  <>
    Es soll ein Gemeinschaftsraum für Treffen, Feste und als Ort für Kinderspiele und unverbindliches Zusammenkommen
    realisiert werden. Die Einrichtung einer Gemeinschaftsküche ermöglicht den Austausch und den Rahmen für gemeinsames
    Kochen und Essen. Kurze direkte Wege zwischen den BewohnerInnen sollen das Miteinander prägen, dafür bildet der
    Gemeinschaftsraum das Zentrum. Der Gemeinschaftsraum steht allen BewohnerInnen des Hauses offen. In Ergänzung des
    Gemeinschaftsraumes ist eine Werkstatt vorgesehen, welche einen Beitrag für gemeinschaftliche Projekte leisten soll.
    Wir wollen Gerätschaften teilen, um Ressourcen zu sparen. So ist es ist nicht notwendig, dass jeder Haushalt eine
    Bohrmaschine oder einen Werkzeugkasten besitzt. An gemeinsamen Projekten zusammenzuarbeiten stärkt den Zusammenhalt,
    „schweißen und löten zusammen“. Das Teilen von Infrastruktur soll sich in unserer Waschküche fortsetzen. Hier ist es
    möglich, Geräte kostensparend anzuschaffen sowie energiesparend und effizient zu nutzen.
  </>
);

export const CommonRooms = {
  className: "commonRooms",
  titlePrimary: "Gemeinschaftsräume",
  titleSecondary: "Gemeinschaftsräume: Begegnung, Zusammenarbeit und Nachhaltigkeit",
  content: text,
};
